import sal from "sal.js";

let mainModule = (function() {
  const init = () => {
    sal();
  };

  return {
    init: init
  };
})();

mainModule.init();
